.mainimg {
  background-image: url('../../images/about-header.png');
  background-size: 100%;
  background-repeat: no-repeat;
  height: 343px;
}

.triangular {
    background: transparent url('../../images/big-triangles.png') no-repeat padding-box;
    height: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
}

.about-hero {
  background: rgba(0,0,0,.65) url('../../images/about-header.png') 0% 0%
    no-repeat padding-box;
  background-size: cover;
  background-position: center right;
  height: 50vh;
}

.text-dark-gray {
  color: #1c2253;
}

.hlongblue {
  background: transparent url('../../images/AdobeStock_231714478.png') 0% 0% no-repeat padding-box;
  background-color: #F4F6FAF5;
  /* min-height: 700px;
  margin-top: 200px; */
}


@media only screen and (max-width:991.97px) {
  .triangular {
    height: 90%;
  }
}


@media only screen and (max-width: 767px) {
  .about-hero {
    background: rgba(0,0,0,.65) url('../../images/about-header-sm.png') 0% 0%
      no-repeat padding-box;
      
    background-size: cover;
    background-position: center left;
    height: 40vh;
  }

  .hlongblue .page-title {
    font-size: 48px;
    line-height: 48px;
  }
}

@media only screen and (max-width:577px) {
  .triangular {
    height: 100%;
  }

  .expect-list p {
    font-size: 15px;
  }
}

@media only screen and (max-width:499.91px) {
  .hlongblue .page-title {
    padding-top: 1.25rem;
    font-size: 36px;
    line-height: 36px;
  }
}

