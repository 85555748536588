.hero-contact {
    background-image: url('../images/hero-contact.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
}

.hero-contact h2 {
    text-align: center;
    font: normal normal bold 58px/64px Soleil;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    vertical-align: middle;
}

.contact-steps {
    justify-content: space-around;
}

.contact-steps h4 {
    text-align: center;
    font: normal normal bold 22px/22px Soleil;
    letter-spacing: 0px;
    color: #1C2253;
}

.contact-steps .luck-md {
    text-align: left;
    font: normal normal normal 62px/22px Lucky Fellas;
}

.contact-steps .right-border {
    width: 5px;
    padding: 0;
    border-right: 1px solid #BBB;
}

.contact-steps h5 {
    text-align: center;
    font: normal normal bold 22px/27px Portada;
    letter-spacing: 0px;
    color: #083F88;
}

.contact-steps p {
    font: normal normal normal 16px/22px Soleil;
    color: #676F86;
}

.contact-steps .clrd-btn {
    font-size: 14px;
    font-family: soleil, sans-serif;
}

.contact-steps .steps-box {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3rem;
    background: #F4F6FAF5 0% 0% no-repeat padding-box;
}

.contact-steps .steps-box .btn-outline-dark {
    width: 95%;
    color: #1C2253;
    border: 2px solid #083F88!important;
    font-family: soleil, sans-serif;
}

.contact-steps .steps-box .btn-outline-dark:hover, .contact-steps .steps-box .btn-outline-dark:focus {
    color: #fff;
    border: 2px solid #083F88!important;
    background-color: #083F88!important;
}

.contact-steps .steps-box h6, .contact-steps .steps-box a {
    line-height: 1.4;
    font: normal normal normal 20px/26px Soleil;
    color: #676F86;
}

.contact-steps .right-column .clrd-btn {
    background-color: #1C2253;
}

.contact-steps .steps-box .btn-outline-orange {
    width: 95%;
    color: #1C2253;
    border: 2px solid #E16D00!important;
    font-family: soleil, sans-serif;
    word-wrap: normal;
}

.contact-steps .steps-box .btn-outline-orange:hover, .contact-steps .steps-box .btn-outline-orange:focus {
    color: #fff;
    border: 2px solid #E16D00!important;
    background-color:  #E16D00!important;
}

.contact-wrapper .contact-form {
    padding: 2.25rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
}


.contact-wrapper .contact-form .form-row {
    padding-bottom: .25rem;
}

.contact-wrapper .contact-form .form-label {
    text-align: left;
    font-family: Soleil;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #1C2253;
    display: inline-block;
    margin-bottom: .5rem;
}

.contact-wrapper .contact-form .form-control {
    background-color: #FCFCFCF5;
    border: 1px solid #EFEFEF;
}

.contact-wrapper .contact-form textarea {
    resize: none!important;
}

.tcp p, .tcp a {
    font-size: 10px!important;
    font-family: Soleil !important;
}

.tcp .consent {
    font-size: 10px!important;
    margin-bottom: 0px;
    font-family: Soleil !important;
}

@media only screen and (max-width: 991.97px) {
    .contact-wrapper .page-title {
        font-size: 38px;
    }

    .contact-steps {
        justify-content: center;
    }

    .contact-steps .right-border {
        border-right: none;
        width: 100%;
        border-bottom: 1px solid #BBB;
    }

    .contact-steps .right-column {
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 767.97px) {
    .hero-contact h2 {
        font-size: 48px;
    }

    .lucky {
        font-size:58px;
    }

    .contact-wrapper .page-title {
        font-size: 32px;
    }

    .contact-steps .steps-box {
        padding-top: 2.5rem;
    }

    .contact-steps .steps-box .btn-outline-orange, .contact-steps .steps-box .btn-outline-dark {
        font-size: 16px;
    }
}

@media only screen and (max-width: 397.97px) {
    .contact-wrapper .contact-form {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.modal-header .modal-title{
    color: #0481b5;
    margin-left: 9vw;
}
.globalApplyModal{
    padding-top: 8vw;
}
.form-check-input[type='radio'] {
    border-radius: 0.25em !important;
}
.form-check-input:checked[type='radio'] {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e')!important;
}

@media screen and (max-width: 600px) {
    .checkBoxDisp {
        display: inline-block !important;
    }
}

@media screen and (min-width: 601px) {
    .checkBoxDisp {
        display: flex;
    }
    .pad-left-iDoNotConsent {
        padding-left: 2.5rem !important;
    }
}
  