@import url("https://use.typekit.net/vtj1crr.css");

@font-face {
  font-family: "Lucky Fellas";
  src: local("Lucky Fellas"),
    url(./fonts/Lucky_Fellas_W00_Regular.ttf) format("truetype");
}

body {
  font-family: soleil, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #676f86;
}

.font-portada {
  font-family: Portada;
}
.lucky {
  font-family: Lucky Fellas, sans-serif;
  font-weight: normal;
  font-size: 78px;
}

.luck-md {
  font-family: Lucky Fellas, sans-serif;
  font-size: 62px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #98daf0;
}
.navy-blue {
  color: #083f88;
}

.light-blue {
  /* color: #00AAE4; */
  color: #98daf0;
}

.baby-blue {
  color: #98daf0;
}

.dark-gray {
  color: #1c2253;
}

.orange-text {
  color: #e16d00;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0px;
}

.page-title {
  line-height: 50px;
  font-family: soleil, sans-serif;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  color: #1c2253 !important;
}

.sub-title {
  line-height: 24px;
  font-family: portada, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  color: #00aae4;
}

.blue-btn {
  background: #083f88 0% 0% no-repeat padding-box;
  border: transparent;
  border-radius: 4px;
  padding: 0.3rem 1rem;
  line-height: 20px;
}

.clrd-btn {
  background: #00aae4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border-color: transparent;
  line-height: 20px;
  font-size: 16px;
  font-family: soleil, sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 0.75rem 1rem;
  margin-left: 1rem;
}

.clrd-btn:hover {
  text-decoration: none;
  background: #00abe4cb 0% 0% no-repeat padding-box;
}

.light-blue-rectangle {
  /* position: absolute; */
  bottom: 0px;
  left: 0;
  background: linear-gradient(to top, #00abe47e 45%, transparent 45%);
}

.md-blue-title {
  font: Regular 20px/24px Portada;
  letter-spacing: 0px;
  color: #00aae4;
}

.big-white-title {
  font: Bold 58px/64px Soleil;
  letter-spacing: 0px;
  color: #ffffff;
}

.btn-orange {
  background: #e16d00 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  padding: 0.35rem 1.75rem;
}

.bg-blue {
  background: #083f88;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.findYourDream {
  font-size: 23px;
  font-weight: 700;
  vertical-align: top;
}

.calc-container {
  border-top: 3px solid #083f88;
}

.calc-box {
  border-bottom: 3px solid #083f88;
}

@media only screen and (max-width: 767px) {
  .big-white-title {
    font-size: 36px;
    line-height: 48px;
  }

  .page-title {
    font-size: 32px;
    line-height: 32px;
  }
  .rect-title {
    height: 32px;
  }
}

@media only screen and (max-width: 577px) {
  .big-white-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 499px) {
  .page-title {
    font-size: 28px;
    line-height: 28px;
  }
  .rect-title {
    height: 28px;
  }
}

@media only screen and (max-width: 399px) {
  .page-title {
    font-size: 24px !important;
    line-height: 24px;
  }
  .rect-title {
    height: 24px;
  }
  .align-items-center {
    margin-bottom: 35px !important;
  }
  .pl-5 {
    margin-left: 0rem !important;
  }

  .cntct {
    margin-left: 0px !important;
    width: 115px !important;
  }

  .dropdown-item a,
  .navLink {
    font-size: 16px;
  }
}

.btn-size {
  width: 100%;
  height: 60px;
}
a.navLink.dropdown-item.active {
  color: #00aae4;
  /* background-color: #007bff !important; */
}

div.video-react-poster {
  background-size: cover !important;
}

.anchor_blank {
  text-decoration: none;
  color: #fff;
}
.anchor_blank:hover {
  text-decoration: none;
  color: rgb(85, 206, 253);
}

.scrollToTop {
  padding: 10px;
}

.overRideTransition {
  color: #23527c;
}

.sitemap-hero {
  background: transparent url("./images/sitemap-hero.jpeg") 0% 0% no-repeat
    padding-box;
  background-size: cover;
  background-position: 100%;
  height: 50vh;
}

.sitemapWrap a {
  color: #1c2253;
  font-weight: bold;
  text-decoration: none;
}

.arrowUp {
  display: inline-block; 
  width: 100%; 
  height: 100%; 
  stroke-width: 0; 
  stroke: white; 
  fill: white;
}

a {
  text-decoration: none;
}

/*   */


/* updated css */


.modalLink:hover,
.modalLink:focus {
  color: #0056b3;
}

.Modal-colors {
  background: #571ef5;
  color: #fff;
}

@media (max-width: 500px) {
  .globalApplyModalWrapper .form-control {
    font-size: 14px;
  }
}

.globalApplyModal {
  z-index: 9999 !important;
}

.globalApplyModal > div > div > div > div {
  font-size: 21px !important;
}

.globalApplyModal > div > div {
  padding: 10px 5px;
  overflow-x: auto;
  background-repeat: no-repeat;
  border-radius: 15px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}

.globalApplyModal > div > div > div {
  border-bottom: none !important;
}

.globalApplyModal label {
  color: #333;
}

.globalApplyModal .invalid-feedback {
  color: #dc3545;
}

.globalApplyModal .modal-header {
  padding: 0 20px;
}

.apply_btn_color {
  color:  #00aae4 !important;
}

.btn {
  cursor: pointer;
}

.apply_btn_color:hover {
  background-color:  #00aae4;
  border: 1px solid #447df8;
  color: #2d51f5 !important;
  transition-duration: 0.6s;
}

.blg-col-0 {
  display: none;
}

.modal-content {
  border-radius: 15px !important;
}

.modal-header {
  border: none !important;
  margin-top: -10px;
  padding-bottom: 0 !important;
}

.modal-content .formHeader {
  font-size: 22px;
  text-align: center;
  color: #00aae4;
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 0 !important;
  padding-bottom: 10px;
}

.modal-content .close {
  color: #4483f8;
}

.modal-content input:not(.form-check-input),
.modal-content select {
  border-left: 2px solid #1d90cc;
  border-right: 2px solid #1d90cc;
}

.modal-content .blg-col-6 {
  display: none;
}

.modal-content h6 {
  color: #484d54;
  font-size: 11px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.modal-content .consent-font {
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}

.modal-content .consent-font a {
  color:#00aae4;
}

.modal-content .consent-font a:hover {
  color: #0056b3;
}

.modal-content .form-check {
  font-size: 12px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.modal-content .btn-submit {
  background-color: #2ba8df;
  border-radius: 25px !important;
  /* padding: 10px 20px !important; */
  font-size: 14px;
  border: 1px solid #2ba8df;
}
.modal-content .btn-submit:hover,
.modal-content .btn-submit:focus {
  background-color: #fff;
  color: #3170f7;
  border-color:  2px solid #4144f3 !important;
  
}
.modal-content .btn-success {
  background-color: #198754;
  color: #fff !important;
  border: 1px solid #198754 !important;
}
.modal-content .btn-success:hover,
.modal-content .btn-success:focus {
  background-color: #149257 !important;
  border: 1px solid #198754 !important;
  color: #fff !important;
}

.expect-list {
  list-style: none;
  list-style-position: inside;
  padding: 0px 0 10px 50px;
  text-indent: -2.5em;
}

.expect-list li {
  margin-bottom: .5rem;
}

.expect-list li::before {
  content:url('./images/check.svg');
  vertical-align: text-top;
  margin-right: 0.6rem; /* Change this to your liking */
}