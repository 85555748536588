.container-fluif .row {
  max-width: 100%;
}

.loan-hero {
    background: transparent url('../images/loan-header.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: 100%;
    height: 50vh;
}

.tru-row {
    background: transparent url('../images/AdobeStock_231714478.png') 0% 0% no-repeat padding-box;
    background-color: #F4F6FAF5;
    background-size: cover;
}

.video-col {
    margin-top: -4.5%;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-react .video-react-big-play-button {
    font-size: 4em;
    transform: translate(-50%, -50%);
    height: 65px;
    width: 65px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 2px solid#da7415;
    background-color: rgb(255 255 255 / 0%);
    border-radius: 50%;
    transition: all 0.4s;
}

  .loan-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 42px #00000012;
    border-radius: 6px;
    min-height: 260px;
    margin-top: 0px;
    margin-bottom: 20px;
    width: calc(30% - ( ( 2% + 2% + 2% ) * 0.25 ) )!important;
    margin-right: 2%;
    margin-left: 2%;
    -webkit-transition: .4s ease;
    transition: .4s ease;
  }

  .loan-boxes:hover {
    cursor: pointer;
    overflow: hidden;
    transform: scale(1.08);
  }

  .loan-boxes h5 {
    text-align: center;
    font: Bold 22px/22px Soleil;
    letter-spacing: 0px;
    color: #E16D00;
  }

  .loan-subfooter {
    background: transparent url('../images/cta-bg.png') 0% 0% no-repeat 
  }

  @media only screen and (max-width:991.97px) {
    .loan-boxes h5 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width:767px) {
    .loan-hero {
      background-position: center center;
      height: 40vh;
  }
  
    .loan-hero .row {
      height: 75%;
    }
    .loan-boxes {
      width:100%!important;
    }
  }

  @media only screen and (max-width:577px) {
    .loan-hero .row {
      height: 100%;
    }

    .loan-boxes {
      margin-right: 4%;
      margin-left: 4%;
    }
  }