.home-hero .container {
  display: flex;
  width: 75%;
  align-items: center;
  padding-top: 8%;
  margin-left: 18%;
}

.home-hero {
  background: transparent url('../images/home-hero.jpeg') 0% 0% no-repeat
    padding-box;
  background-size: cover;
  height: 100vh;
}

.hero-contents {
  display: flex;
  align-items: center;
}

.hero-text h1,
.hero-text .h2 {
  text-align: left;
  line-height: 64px;
  font-family: soleil, sans-serif;
  font-size: 58px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1 !important;
}

.hero-text h1 {
  position: relative;
  color: #fff;
  z-index: 2;
  margin-bottom: 0;
}
.hero-text h1 {
  position: relative;
}

.box-wrapper {
  box-shadow: 0px 12px 42px #00000012;
  border-radius: 6px;
  padding-left: 3rem;
  overflow: hidden;
}

.blue-rectangle {
  position: absolute;
  width: 100%;
  height: 88%;
  bottom: 5px;
  left: 0;
  background: linear-gradient(to top, #00AAE4 45%, transparent 45%);
  z-index: -1;
  padding-left: 10px !important;
}

.home-box {
  background: transparent url('../images/girl-dog.png') no-repeat;
  background-position: top right;
  height: 571px;
}

.large-text .sub-title {
  font-size: 24px;
}

.large-text p {
  font-size: 22px;
  line-height: 28px;
}

.blue-box-container {
  width: 95%!important;
}

.blue-box {
  background: url('../images/square-bg.png');
  background-position: center center;
  background-size: cover;
  position: relative;
  max-width: 22%!important;
  /* min-width: 320px!important; */
  margin-top: 2%;
  margin-bottom: 10px;
  border-radius: 6px;
  z-index: -1;
}

.blue-box2 {
  background: transparent url('../images/square-bg2.png');
  background-position: center center;
  background-size: cover;
  position: relative;
  /* min-width: 320px!important; */
  max-width: 22%!important;
  margin-top: 2%;
  margin-bottom: 10px;
  border-radius: 6px;
  z-index: -1;
}

.home-numbers {
  font-family: Lucky Fellas, sans-serif;
  font-weight: normal;
  font-size: 200px;
  line-height: 173px;
  color: #e16d00;
  padding-left: 8%;
  margin-top: -23%;
  margin-bottom: 0;
}

.boxes-curver {
  position: absolute;
  top: -30px;
  width: 80%!important;
  left: 6%;
  z-index: 2;
}

.box-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -8%!important;
  /* min-width: 300px!important; */
}

.box-contents .boxIcon {
  align-self: center;
  padding-bottom: 1.5rem;
}

.blue-box h4,
.blue-box2 h4 {
  line-height: 22px;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: #f4f6faf5;
}

.box-sub {
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  color: #f4f6faf5;
}

.blue-box2 h2 {
  font-family: Lucky Fellas, sans-serif;
  font-weight: normal;
  font-size: 62px;
  line-height: 22px;
  color: #98daf0;
}

.homescoutWidget {
  height: 80px!important;
  padding-top: 1.5rem;
}

.review-box .review {
  font: Italic 18px/24px Soleil;
  letter-spacing: 0px;
  color: #083f88;
  padding-top: 0rem;
}

.card {
  border: transparent !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 42px #00000012;
  border-radius: 6px;
}

.user-review {
  font: Bold 18px/28px Soleil;
  letter-spacing: 0px;
  color: #676f86;
}

.box-row {
  margin-top: -7rem!important;
  justify-content: space-evenly;
}

.blog-box {
  background: transparent url('../images/Group 902.png') 0% 0% no-repeat
    padding-box;
  background-position: center center;
  background-size: cover;
}

.blog-box .card {
  height: 106%;
}

.blog-box .col-lg-3 {
  max-width: 18%!important;
}

.blog-box .card-text {
  text-align: left;
  font: Bold 18px/20px Soleil;
  letter-spacing: 0px;
  color: #083f88;
}

.blog-box .post-link {
  color: #1C2253;
  border-bottom: 3px solid #E16D00;
  text-decoration: none;
}

.blog-date {
  font: normal normal normal 14px/22px Portada;
  letter-spacing: 0px;
  color: #676F86;
}

.rect-title {
  position: relative;
  height: 48px;
}

.medium-text {
  display: none;
}

.reviews-row {
  justify-content: space-evenly;
}

.review-box {
  max-width: 30%!important;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1750.75px) {
  .home-box {
    background: transparent url('../images/girl-dog-lg.png') no-repeat;
    background-position: top right;
    /* height: 571px; */
  }
}

@media only screen and (max-width: 1596.75px) {
  .home-box {
    background: transparent url('../images/girl-dog-md.png') no-repeat;
    background-position: top right;
    height: 571px;
  }

  .blog-box .col-lg-3 {
    max-width: 21%!important;
  }

  .blue-box {
    max-width: 24%!important;
    /* min-width: 320px!important; */
  }
  
  .blue-box2 {
    /* min-width: 320px!important; */
    max-width: 24%!important;

  }

  .large-text p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 1437.95px) {
  .homescoutWidget {
    height: 75px!important;
    padding-top: 0rem;
  }
}

@media only screen and (max-width: 1196.75px) {
  .box-wrapper {
    height: 92vh;
    padding-left: 1rem;
  }

  .box-row {
    margin-top: -5%!important;
  }

  .blue-box-container {
    width: 100%!important;
  }

  .medium-text {
    display: block;
  }
  .large-text {
    display: none;
  }

  .home-box {
    background: transparent url('../images/girl-dog.png') no-repeat;
    background-position: top right;
    background-size: contain;
    margin-top: 2%;
    height: 571px;
  }  

  .home-numbers {
    margin-top: -45%!important;
  }

  .blog-box .page-title {
    font-size: 38px;
    line-height: 38px;
  }

  .blog-box .col-lg-3 {
    max-width: 22%!important;
  }
}

@media only screen and (max-width: 992.75px) {
  .box-wrapper {
    height: 86vh;
    padding-right: 1rem;
  }

  .box-row {
    margin-top: -6%!important;
  }

  .blue-box-container {
    width: 90%!important;
  }

  .boxes-curver {
    display: none;
  }

  .home-numbers {
    margin-top: -30%!important;
  }

  .bottom-box {
    margin-top: 3rem;
  }

  .blue-box {
    max-width: 45%!important;
  }

  .blue-box2 {
    max-width: 45%!important;
  }

  .homescoutWidget {
    height: 65px!important;
    padding-top: .75rem;
  }

  .box-contents {
    margin-top: -12%!important;
    padding-bottom: 1rem;
  }

  .box-contents .boxIcon {
    padding-bottom: 1rem;
  }

  .review-box {
    max-width: 100%!important;
  }

 .blog-box .col-lg-3 {
    max-width: 60%!important;
    margin-bottom: 1.5rem;
  }

  .blog-box .card {
    height: 100%;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 768.75px) {
  .box-wrapper {
    height: 75vh;
  }
 .blog-date{
  margin-top: 1rem;
 }
  .box-row {
    margin-top: -6%!important;
  }

  .blue-box {
    max-width: 48%!important;
    margin-bottom: 3.5rem;
  }

  .blue-box2 {
    max-width: 48%!important;
    margin-bottom: 3.5rem;
  }

  .bottom-box {
    margin-top: 2%;
  }

  .box-contents {
    margin-top: -15%!important;
  }

  .home-numbers {
    margin-top: -18%!important;
    padding-left: 1%;
  }

  .blog-box .col-lg-3 {
    max-width: 70%!important;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 698.75px) {
  .box-wrapper {
    height: 74vh;
  }

  .box-row {
    margin-top: -5%!important;
  }

}

@media only screen and (max-width: 598.75px) {
  .box-wrapper {
    height: 74vh;
  }

  .box-row {
    margin-top: -5.5%!important;
  }

  .box-contents {
    margin-top: -18%!important;
  }
}

@media only screen and (max-width: 575.95px) {
  .blue-box {
    max-width: 90%!important;
  }

  .blue-box2 {
    max-width: 90%!important;
    margin-bottom: 3.5rem;
  }

}

@media only screen and (max-width: 476.75px) {
  .box-wrapper {
    height: auto;
    margin-bottom: 5%;
  }
  .home-box {
    height: 250px;
}

  .box-row {
    margin-top: -5%!important;
  }

  .hero-text h1,
  .hero-text .h2 {
  font-size: 2rem !important;
  }

  .page-title{
    font-size: 24px;
  }
  .blue-box, .blue-box2 {
    min-height: 250px;
  }

  .home-numbers {
    font-size: 150px;
    margin-top: -25%!important;
  }

  .box-contents {
    margin-top: -22%!important;
  }

  .box-contents .boxIcon {
    width: 75px;
  }

  .blog-box .col-lg-3 {
    max-width: 100%!important;
  }
}

@media only screen and (max-width: 399.75px) {
  .blue-box, .blue-box2 {
    min-height: 225px;
  }
  .box-row {
    margin-top: -7%!important;
  }

  .box-contents {
    margin-top: -20%!important;
  }

  .box-contents .boxIcon {
    width: 55px;
  }

  .home-numbers {
    margin-top: -30%!important;
  }
}

@media only screen and (max-width: 375.75px) {
  .box-row {
    margin-top: -7%!important;
  }
}
.globalApplyModal{
  padding-top: 8vw;
}
.modal-header .modal-title{
  color: #0481b5;
  margin-left: 9vw;
}
@media only screen and (max-width: 320px) {
  .hero-contents {
  margin-top: 18vw !important;
  }
}
@media only screen and (min-width: 475px)and  (max-width: 577px) {
  .hero-contents {
  margin-top: 15vw !important;
  }
}
@media only screen and (max-width: 991px) {
  .home-hero {
  padding-top: 6rem !important;
  }
}
@media only screen and (min-width: 992px) {
  .home-hero {
  padding-top: 8rem !important;
  }
}