footer {
    background: #1C2253 0% 0% no-repeat padding-box;
}

footer .container-fluid {
    width: 75%;
}
.twitlogo{
    margin-top: -2px;
}
footer p {
    font-size: 16px;
    line-height: 20px;
}

.footer-list {
    align-self: flex-end;
}

.footer-list p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0px;
    margin-bottom: 0px;
}

.footer-list a {
    color: #F4F6FAF5!important;
    text-decoration: none;
}

.footer-list a:hover, .footer-list a:focus {
    text-decoration: underline;
}

footer h2 {
    font-family: Lucky Fellas, sans-serif;
    font-weight: normal;
    font-size: 44px;
    line-height: 22px;
    color: #98DAF0;
}

.footer-bottom, .footer-bottom a {
    color: #BBB;
}

.footer-disclaimer a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.footer-disclaimer a:hover {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: underline;
}

.footer-line {
    border: 1px solid #BBB;
    opacity: 0.5;
}

.footer-disclaimer p {
 font-size: 14px;
}

img[usemap], map area{
    outline: none;
}

@media only screen and (max-width: 1299.75px) {
    footer .container-fluid {
        width: 90%;
    }
  }

@media only screen and (max-width: 976.75px) {
    .footer-list {
        align-self: flex-start;
    }

    footer .container-fluid {
        width: 95%;
    }
  }

  @media only screen and (max-width: 766.75px) {
    .footer-list p {
        line-height: 34px;
    }
  }
  #ModalView{
    padding-top: 8vw;
}
.modal-header .modal-title{
    color: #0481b5;
    margin-left: 9vw;
}