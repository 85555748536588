.hero-fha {
    position: relative;
    background-attachment: fixed;
    background-image: url(../images/hero-image.jpg);
    background-size: 2553px 1073px;
    opacity: 1;
    transition-duration: 600ms;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 75vh!important;
    overflow: hidden;
}

.hero-fha .overlay {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#fff;
    opacity: .85;
    clip-path: polygon(
      0 0, 
      82% 0,
      45% 90%,
      0 100%
    );
}

.hero-fha .hero-content {
    position: relative;
    margin-bottom: -5%;
    z-index: 1;
}

.hero-fha .hero-content .row {
    justify-content: space-around;
}

.hero-fha .right-triangle {
    background: url('../images/right-triangle.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 35%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.hero-fha .hero-content h2 {
    font-family: Lucky Fellas, sans-serif;
    font-weight: normal;
    font-size: 58px;
    line-height: 64px;
    color: #083F88;
}

.hero-fha .hero-content p {
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0px;
}

.hero-fha .hero-content ul li {
    font-size: 14px;
    line-height: 24px;
    padding: .25rem 0;
}

.hero-fha .hero-content ul li::before {
    content: url('../images/list-arrow.svg');
    padding-right: .25rem;
}

.hero-fha .hero-content .list-blue-text {
    color: #1C2253;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
}

.hero-fha .hero-form {
    padding: 2.25rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
}

.hero-form .form-row {
    padding-bottom: .25rem;
}

.hero-form .form-label {
    text-align: left;
    font-family: Soleil;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #1C2253;
    display: inline-block;
    margin-bottom: .5rem;
}

.hero-form .form-control {
    background-color: #FCFCFCF5;
    border: 1px solid #EFEFEF;
}

.hero-form textarea {
    resize: none!important;
}

.hero-form h4 {
    font-family: Lucky Fellas, sans-serif;
    font-weight: normal;
    font-size: 38px;
    color: #083F88;
    margin-bottom: 0;
}

.hero-fha .hero-form button {
    padding: .5rem 2.75rem;
}

.hero-fha .hero-form button:hover, .hero-fha .hero-form button:focus {
    outline: none;
}

.hero-fha .hero-form .tcp p {
    line-height: 12px;
}
.fha-perks {
    padding: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.fha-perks .card {
    border: transparent !important;
    background: transparent;
    box-shadow: none;
    border-radius: 6px;
}

.fha-perks .card img {
    height: 95px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 1.75rem;
}

.fha-perks .card .card-body {
    position: relative;
    padding: 0rem;
    bottom: 2%;
}

.fha-perks .card p {
    text-align: center;
    font: normal normal bold 22px/24px Soleil;
    letter-spacing: 0px;
    color: #1C2253;
}

.fha-perks .card .button, .fha-perks .card button {
    font: normal normal 600 16px/20px Soleil;
    letter-spacing: 0px;
    background: transparent;
    color: #FFFFFF;
    border: transparent;
    border-radius: 4px;
    padding: .75rem;
    width: 100%;
}

.fha-perks .card .button:hover, .fha-perks .card button:hover {
    opacity: .85;
    text-decoration: none;
}

.fha-perks .card button:focus {
    outline: none!important;
}

.fha-perks .card-footer {
    display: flex;
    width: 100% ;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
    border-top: none;
    padding-bottom: 0;
    position: absolute;
    bottom: 0px!important;
    padding: .75rem!important;
}

@media only screen and (min-width: 992px) and (max-device-width: 1299px) {
    .hero-fha {
        min-height: 100vh!important;
    }

    .hero-fha .hero-form {
        padding: 1.25rem .75rem;
    }
}

@media only screen and (max-width: 991.97px) {
    .hero-fha .hero-content {
        padding-top: 0%;
    }

    .hero-fha .overlay {
        clip-path: polygon(
          0 0, 
          100% 0,
          85% 80%,
          0 100%
        );
    }

    .hero-fha .hero-content.container {
        /* margin-left: 2%; */
        /* margin-top: 450px; */
        max-width: 80%;
    }

    .hero-fha .right-triangle {
        display: none;
    }

    .hero-fha .hero-content .row {
        justify-content: center;
    }

    .fha-perks {
        /* margin-top: 520px; */
    }
}

@media only screen and (max-width: 767.97px) {
    .hero-fha .hero-content.container {
        /* margin-top: 450px; */
        max-width: 85%;
    }

    .hero-fha .hero-content h2 {
        font-size: 48px;
    }

    .hero-fha .hero-content p {
        font-size: 18px;
        line-height: 24px;
    }

    .fha-perks {
        padding: 1rem;
    }
}

@media only screen and (max-width: 575.97px) {
    .hero-fha .hero-content h2 {
        /* padding-top: 4rem; */
    }
}

@media only screen and (max-width: 497.97px) {
    .hero-fha {
        min-height: 85vh!important;
    }

    .hero-fha .hero-content h2 {
        font-size: 40px;
        padding-top: 6.5rem;
    }

    .hero-fha .hero-content p {
        font-size: 16px;
    }

    .hero-fha .hero-content .list-blue-text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 397.97px) {
    
    .hero-fha .hero-content h2 {
        font-size: 30px;
        padding-top: 6.5rem;
    }

    .hero-fha .hero-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .hero-fha .hero-content .list-blue-text {
        font-size: 14px;
    }
}